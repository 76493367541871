@import '../../../../utils/Variables.scss';

.entitlement-content-section .content-no-preview {
  max-width: 100%;
}

.goodie-bag-merchant-table {
  margin-top: 30px;
  table-layout: fixed;
  width: 100%;
}

.goodie-bag-merchant-table tr th {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  word-wrap: break-word;
}

.goodie-bag-merchant-table tr td {
  border: 1.5px solid #dadada;
  padding: 32px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  word-wrap: break-word;
}

.lebel-right-button,
.lebel-right-button:hover,
.lebel-right-button:focus,
.lebel-right-button:active {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  color: $linkBtnColor;
  font-size: 16px;
  font-weight: 800;

  padding-top: 0px;
}

.goodie-bag-mini-program {
  margin-top: 30px;
  font-family: Mulish;
  font-style: normal;
}

.goodie-bag-mini-program-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
  margin-right: 5px;
}

.goodie-bag-mini-program-id {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  margin-right: 10px;
}
